import React from "react"
import tw, { styled } from "twin.macro"
import { P, H3 } from "../page-elements"
import { FormComponent, FormProps, Schema, SetStatus } from "../common/form"
import {
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_FAILURE,
  phoneRegExp,
  emailRegExp,
} from "../../constants/component"
import { InputBlock } from "../common/input-block"
import { TextareaBlock } from "../common/textarea-block"

const Form = tw.form`
    flex
    flex-col
    justify-center
    md:px-10
`

const Button = styled.button`
  ${tw`
    inline-block

    px-4 
    my-4
    md:px-5 
    xl:px-4 
    py-2 
    md:py-4 
    xl:py-3 

    md:text-lg 
    leading-tight 
    shadow-md
    disabled:bg-gray-500

    text-black
    bg-transparent
    hover:bg-primary
    border-2
    border-charcoal-dark
    hover:border-primary
    hover:bg-primary
    hover:text-white
    uppercase
    `}
  &:hover {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    filter: brightness(85%);
  }
`

const SuccessMessage = tw.div`
  text-center
`

const StyledFormError = tw.p`
    text-red-500
    block
    text-xs
    my-0
    mx-2
    text-left
`

interface ContactForm {
  name: string
  email: string
  // phone: string
  subject: string
  message: string
  [key: string]: string
}

const encode = (data: ContactForm) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const initialValues = {
  name: "",
  email: "",
  // phone: "",
  subject: "",
  message: "",
}

const schema: Schema = {
  name: {
    required: true,
  },
  email: {
    required: true,
    format: emailRegExp,
  },
  // phone: {
  //   required: true,
  //   format: phoneRegExp,
  // },
  subject: {
    required: true,
  },
  message: {
    required: true,
  },
}

const EmailContactForm = () => {
  const handleSubmit = (
    values: any,
    {
      setStatus,
      validateFields,
    }: { setStatus: SetStatus; validateFields: () => boolean }
  ) => {
    const hasErrors = validateFields()
    if (hasErrors) {
      return
    }

    setStatus(STATUS_PENDING)

    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "email-contact",

        ...values,
      }),
    })
      .then(() => {
        setStatus(STATUS_SUCCESS)
      })
      .catch(error => {
        setStatus(STATUS_FAILURE)
      })
  }

  return (
    <FormComponent initialValues={initialValues} schema={schema}>
      {({
        values,
        errors,
        status,
        onChange,
        onFocus,
        hasErrors,
        setStatus,
        onBlur,
        validateFields,
      }: FormProps) => {
        const isPending = () => {
          return status === STATUS_PENDING
        }
        const isSuccess = () => {
          return status === STATUS_SUCCESS
        }
        const pending = isPending()
        const success = isSuccess()
        return success ? (
          <SuccessMessage>
            <H3 className="text-header">Thanks for reaching out!</H3>
            <P color="dark">We'll get back to as soon as possible!  For the quickest response, please call us at (608) 246-4200</P>
          </SuccessMessage>
        ) : (
          <Form
            name="email-contact"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={e => {
              e.preventDefault()
              handleSubmit(values, { setStatus, validateFields })
            }}
          >
            <input type="hidden" name="form-name" value="email-contact" />
            <input type="hidden" name="bot-field" />
            <InputBlock
              placeholder="Name"
              name="name"
              ariaLabel="Name"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={values.name}
              errors={errors}
            />

            <InputBlock
              placeholder="Email Address"
              ariaLabel="Email Address"
              name="email"
              type="email"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={values.email}
              errors={errors}
            />

            {/* <InputBlock
              placeholder="Phone Number"
              name="phone"
              ariaLabel="Phone"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={values.phone}
              errors={errors}
            /> */}

            <InputBlock
              placeholder="Subject"
              name="subject"
              ariaLabel="Subject"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={values.subject}
              errors={errors}
            />

            <TextareaBlock
              placeholder="Description of Job"
              name="message"
              ariaLabel="Message"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={values.message}
              errors={errors}
            />

            {status !== undefined && status === STATUS_FAILURE && (
              <StyledFormError>
                Oops! Something went wrong. Please try again.
              </StyledFormError>
            )}

            <Button type="submit" disabled={hasErrors() || pending}>
              Leave Message
            </Button>
          </Form>
        )
      }}
    </FormComponent>
  )
}

export default EmailContactForm
