import React from "react"

interface FormComponentProps {
  initialValues: { [key: string]: object | string }
  schema: { [key: string]: object }
}

export interface FormProps extends FormHelpers {
  values: { [key: string]: string }
  errors: { [key: string]: { message: string } }
  status: string
}

export interface Schema {
  [key: string]: { required?: boolean; format?: RegExp }
}

export type SetStatus = (status: string) => void

export interface FormHelpers {
  onChange(): void
  onFocus(): void
  hasErrors(): boolean
  setStatus(): void
  onBlur(): void
  validateFields(): boolean
}

const FormReducer = (state, action) => {
  switch (action.type) {
    case "change":
      const errors = { ...state.errors }
      delete errors[action.payload.name]
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value,
        },
        status: "idle",
        errors,
      }
    case "focus":
      return {
        ...state,
        touched: {
          ...state.touched,
          [action.payload.name]: true,
        },
        status: "idle",
      }
    case "blur":
      return state
    case "error":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: { message: action.payload.message },
        },
      }
    case "status": {
      return {
        ...state,
        status: action.payload.status,
      }
    }
    default:
      throw new Error("This action does not exist")
  }
}

export const FormComponent: React.FC<FormComponentProps> = ({
  children,
  initialValues,
  schema,
}) => {
  const initialState = {
    status: "idle",
    values: initialValues,
  }
  const [state, dispatch] = React.useReducer(FormReducer, initialState)

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({
      type: "change",
      payload: {
        name: event.currentTarget.name,
        value: event.currentTarget.value,
      },
    })
  }

  const onFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({
      type: "focus",
      payload: {
        name: event.currentTarget.name,
      },
    })
  }

  const onBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    dispatch({
      type: "blur",
      payload: {
        name,
      },
    })
    validateField(event)
  }

  const validateFields = () => {
    let hasErrors = false
    Object.keys(state.values).forEach(key => {
      const mockEvent = {
        name: key,
        value: state.values[key],
      }

      const error = checkValidity(schema[key], mockEvent)
      if (error) {
        dispatch({
          type: "error",
          payload: {
            name: key,
            message: error,
          },
        })
        hasErrors = true
      }
    })
    return hasErrors
  }

  const checkValidity = (schema, { name, value }) => {
    let hasErrors = false
    if (!schema) {
      return hasErrors
    }
    if (schema.required && !value) {
      hasErrors = true
      const requiredMessage = `${name} is a required field.`
      return requiredMessage
    }
    if (schema.format) {
      const pass = schema.format.test(value)

      if (!pass) {
        hasErrors = true
        let patternMessage = `${name} is not a valid format.`
        if (name === "email") {
          patternMessage = `${name} is not a valid format. (e.g. example@gmail.com)`
        }
        if (name === "phone") {
          patternMessage = `${name} is not a valid format. (e.g. 1234567890)`
        }
        return patternMessage
      }
    }
  }

  const validateField = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    const field = schema[name]
    const error = checkValidity(field, { name, value })
    if (!error) {
      return
    }

    dispatch({
      type: "error",
      payload: {
        name,
        message: error,
      },
    })
  }

  const setStatus = (status: string) => {
    dispatch({
      type: "status",
      payload: {
        status,
      },
    })
  }

  const hasErrors = () => {
    if (!state.errors) {
      return false
    }
    return Object.keys(state.errors).some(key => state.errors[key])
  }

  return children({
    values: state.values,
    errors: state.errors,
    touched: state.touched,
    status: state.status,
    validateField,
    validateFields,
    onChange,
    onBlur,
    onFocus,
    setStatus,
    hasErrors,
  })
}
