import tw, { styled } from "twin.macro"

export const StyledErrorMessage = styled.div`
  ${tw`
    text-red-500
    block
    text-xs
    my-0
    mx-2
    text-left
  `}
`
