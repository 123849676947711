import React from "react"
import { StyledErrorMessage } from "../styles/error-message"
import tw from "twin.macro"

interface InputBlockProps {
  placeholder: string
  name: string
  ariaLabel: string
  value: string
  errors?: { [key: string]: { message: string } }
  onFocus(): void
  onChange(): void
  onBlur(): void
}

const StyledTextarea = tw.textarea`
    text-base
    text-header
    placeholder-gray-500
    border
    border-gray-500
    mt-4
    py-2
    px-2
    focus:outline-none
    focus:border-primary
    h-32
    w-full
`

export const TextareaBlock = ({
  placeholder,
  name,
  ariaLabel,
  value,
  errors,
  onFocus,
  onChange,
  onBlur,
}: InputBlockProps) => {
  return (
    <>
      <StyledTextarea
        type="textarea"
        placeholder={placeholder}
        name={name}
        aria-label={ariaLabel || name}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
      {errors && errors[name] && (
        <StyledErrorMessage>{errors[name].message}</StyledErrorMessage>
      )}
    </>
  )
}
